<template>
  <header class="top">
    <hr />
    <h1>R O M A N</h1>
    <h1>T U R N E R</h1>
    <h4>FULLSTACK DEVELOPER</h4>
  </header>
</template>

<style scoped>
.top {
  padding-bottom: 10px;
  width: 100%;
  height: 190px;
  text-align: left;
}
.top h1 {
  font-size: 3em;
  margin: 0;
}
.top hr {
  max-width: 100px;
  margin: 0px;
  border: solid 1px #ebebeb;
  border-top: 5px solid #77c9e7;
  border-left: 3px solid #77c9e7;
  border-right: 3px solid #77c9e7;
  border-bottom: 7px solid black;
}
</style>
