<template>
  <section class="jobs">
    <h2>Work Experience</h2>
    <article class="job">
      <div class="title">
        <h3>Markit Meals</h3>
        <p>Seattle, WA</p>
      </div>
      <div class="title">
        <p>
          <em>Founder: </em>
          <a href="https://markitmeals.com/" target="_blank">Website</a>
        </p>
        <p>04/2020 - 01/2021</p>
      </div>
      <p class="subtitle">
        Coordinated a local meal-kit service to utilize a commercial space that
        was closed due to Covid. Managed client response, business licensing,
        advertisement, product quality, staffing, design, and delivery.
      </p>
      <ul class="details">
        <li>
          Curated recipes from sourcing local Pike Place purveyors maintaining
          relationships during a time of crisis.
        </li>
        <li>
          Worked with Pike Place Market Food Bank on fundraising, donating a
          percentage of each sale.
        </li>
        <li>
          Created the website and infrastructure for the ecommerce site and fell
          in love with Software Engineering.
        </li>
      </ul>
      <Collapsable buttonText="Details"> <MMCollapsableContent /></Collapsable>
    </article>
    <article class="job">
      <div class="title">
        <h3>Can Can Culinary Cabaret</h3>
        <p>Seattle, WA</p>
      </div>
      <div class="title">
        <p>
          <em>Executive Chef</em>
        </p>
        <p>06/2015 - 02/2020</p>
      </div>
      <p class="subtitle">
        Fostered a large paradigm shift by being focused on community building.
        Leveraged the talents of the team to develop a fantastic work culture
        and created the most profitable era in company history.
      </p>
      <ul class="details">
        <li>
          Solved inventory management deficits by redesigning the inventory
          control system.
        </li>
        <li>
          Improved overhead expenses by expanding training programs to limit
          waste, maintained positive relationships with local purveyors, and
          designed seasonal menus to reduce total food cost from 34-37% to a
          strict 19-22%.
        </li>
        <li>
          Instituted new systems that reduced redundancies and cut labor costs
          from 30-33% to 14%.
        </li>
      </ul>
      <Collapsable buttonText="Explore"
        ><CanCanCollapsableContent
      /></Collapsable>
    </article>
  </section>
</template>

<script>
import Collapsable from "../../basic-components/Collapsable.vue";
import CanCanCollapsableContent from "./CanCanCollapsableContent";
import MMCollapsableContent from "../technical-projects/markit-meals/MMCollapsableContent";
export default {
  components: { Collapsable, MMCollapsableContent, CanCanCollapsableContent },
};
</script>

<style scoped>
section {
  text-align: left;
}
article {
  margin-top: 10px;
}
p {
  margin: 0;
}
ul {
  margin: 0;
}
h2,
h3 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.title p,
h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.title {
  display: flex;
  justify-content: space-between;
  justify-content: baseline;
}
</style>
