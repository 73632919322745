<template>
  <MobileMenu />
</template>

<script>
import MobileMenu from "../mobile/MobileMenu.vue";

export default {
  name: "MobileView",
  components: { MobileMenu },
};
</script>
