<template>
  <section class="container">
    <h2>Technical Skills</h2>
    <article class="tech-info">
      <p>
        <strong>Languages: </strong>

        JavaScript, HTML, CSS, Ruby.
      </p>
    </article>
    <article class="tech-info">
      <p>
        <strong>Libraries: </strong>
        React.js, CRA, Vue.js Next.js, Node.js, React Router,Tailwind, JSS,
        Material-Ui, Bootstrap, Semantic-Ui, Vuetify, Ruby on Rails, FastAPI
        Serializer, Nokogiri, Kimurai, Sinatra, Rack, Bcrypt, JWT, Redux
        Toolkit: Immer, RTK Query, Thunk.
      </p>
    </article>
    <article class="tech-info">
      <p>
        <strong>Databases: </strong>
        PostgreSQL, ActiveRecord, SQL, SQLite.
      </p>
    </article>
  </section>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  width: 100%;
}
.tech-info {
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 500px;
}
p {
  margin: 5px;
}
</style>
